import { IcebergIcon, useLocalStorage } from "@tradesolution/iceberg-ui-react";
import { Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./index.scss";
import { useEffect, useState } from "react";

export interface SubLink {
    label: string;
    link: string;
    icon?: string;
}

export interface NavLink2 {
    label: string;
    link: string;
    icon: string;
    roles?: string[]; // which roles are allowed to see this link
    subLinks?: SubLink[];
}

export interface SidebarProps {
    navLinks: NavLink2[];
}

const Sidebar = (props: SidebarProps) => {

    const location = useLocation();

    const [sidebarExpanded, setSidebarExpanded] = useLocalStorage<boolean>("sidebar-expanded", true);
    const sidebarWidth = sidebarExpanded ? "250px" : "55px";

    //repsonsive mobile
    /*
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    */

    return (
        <>
                
        <div className="iceberg-navbar-left d-flex flex-column flex-shrink-0 d-none d-sm-block" style={{ width: sidebarWidth, position: "sticky", top: "58px" }}>

            <ul className="nav flex-column nav-pills mb-auto">
                <a className="nav-link" role="button" onClick={() => setSidebarExpanded(!sidebarExpanded)} style={{ whiteSpace: "nowrap" }}>
                    <span style={{ marginRight: "1rem" }}>{sidebarExpanded ? <IcebergIcon icon="chevron-left" /> : <IcebergIcon icon="chevron-right" />}</span> skjul
                </a>
                {props.navLinks.map(l => (
                    <li key={l.link}>
                    <Nav.Link title={l.label} as={Link} aria-label={l.label} to={l.link} className={l.link === location.pathname ? "active" : ""} >
                        <span style={{ marginRight: "1rem" }}><IcebergIcon icon={l.icon as any} /></span> <span>{l.label}</span>
                    </Nav.Link>
                    </li>))}
            </ul>
            <ul className="nav flex-column nav-pills">
                <li>
                    <a className="nav-link" href="http://tradesolution.no" style={{ marginBottom: "auto" }}>
                        <IcebergIcon icon="startsiden" color="#003b6e" /></a>
                </li>
            </ul>
        </div>
        
        </>

        /*
        <div className={`iceberg-navbar ${isMobile ? 'mobile-navbar' : 'iceberg-navbar-left d-flex flex-column flex-shrink-0'}`} style={{ width: sidebarWidth, position: isMobile ? 'fixed' : 'sticky', bottom: isMobile ? '0' : 'auto', top: isMobile ? 'auto' : '58px' }}>
        <ul className={`nav ${isMobile ? 'nav-pills nav-justified' : 'flex-column nav-pills mb-auto'}`}>
            {!isMobile && (
                <a className="nav-link" role="button" onClick={() => setSidebarExpanded(!sidebarExpanded)} style={{ whiteSpace: "nowrap" }}>
                    <span style={{ marginRight: "1rem" }}>{sidebarExpanded ? <IcebergIcon icon="chevron-left" /> : <IcebergIcon icon="chevron-right" />}</span> skjul
                </a>
            )}
            {props.navLinks.map(l => (
                <li key={l.link} className="nav-item">
                    <Nav.Link title={l.label} as={Link} aria-label={l.label} to={l.link} className={l.link === location.pathname ? "active" : ""} >
                        <span style={{ marginRight: !isMobile ? "1rem" : "0" }}><IcebergIcon icon={l.icon as any} /></span> {!isMobile && <span>{l.label}</span>}
                    </Nav.Link>
                </li>
            ))}
        </ul>
        {!isMobile && (
            <ul className="nav flex-column nav-pills">
                <li>
                    <a className="nav-link" href="http://tradesolution.no" style={{ marginBottom: "auto" }}>
                        <IcebergIcon icon="startsiden" color="#003b6e" /></a>
                </li>
            </ul>
        )}
    </div>*/
    );
}

export default Sidebar;