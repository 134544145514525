import { useContext } from 'react';
import { ToastContext } from './ToastProvider';

export default function useToaster() {
  const toasterContext = useContext<any>(ToastContext);
  
  return {
    info: (title: string, message: string) => {
      toasterContext.info(title, message);
    },

    success: (title: string, message: string) => {
      toasterContext.success(title, message);
    },
    error: (title: string, message: string | { key: string; errors: string[] }[], callback: void) => {
      toasterContext.error(title, message, callback);
    },
    processing: (title: string, message: string, onCancel: () => void) => {
      toasterContext.processing(title, message, onCancel);
    }
  };
}