import * as signalR from "@microsoft/signalr";

// to avoid multiple connections, we will use a singleton pattern

const getBaseUrl = () => {
    let baseUrl = 'https://einsteinapi.tradesolution.no';
    if (window.location.host.indexOf('dev') > -1)
        baseUrl = 'https://einsteinapi-dev.tradesolution.no';

    if (window.location.host.indexOf('localhost') > -1)
        //this.baseUrl = 'https://adminportalenapi-dev.tradesolution.no/api';
        baseUrl = 'https://localhost:7255';

    return baseUrl;
}

const hubURL = getBaseUrl() + "/feilmerkingerhub"; //or whatever your backend port is
class Connector {
    private connection: signalR.HubConnection;
    public onFeilMerkingerUpdated?: () => void;
    static instance: Connector;
    constructor() {
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(hubURL)
            .withAutomaticReconnect()
            .build();
        this.connection.start().catch(err => document.write(err));
        this.connection.on("FeilmerkingerUpdated", () => {
            if (this.onFeilMerkingerUpdated) {
                this.onFeilMerkingerUpdated();
            }
        });
    }

    public static getInstance(): Connector {
        if (!Connector.instance)
            Connector.instance = new Connector();
        return Connector.instance;
    }
}
export default Connector.getInstance;