import axios from "axios";
import { AISessionEntity, CreateFeilmerkingCommand, Feilmerking, ImageUploadResponse, SuggestionDto, TilbakeMeldingerFilter } from "./model";

export const getBaseUrl = () => {
    let baseUrl = 'https://einsteinapi.tradesolution.no';
    if (window.location.host.indexOf('dev') > -1)
        baseUrl = 'https://einsteinapi-dev.tradesolution.no';

    if (window.location.host.indexOf('localhost') > -1)
        //this.baseUrl = 'https://adminportalenapi-dev.tradesolution.no/api';
        baseUrl = 'https://localhost:7255';

    return baseUrl;
}
const EinsteinApi = {
    ingrediensvask: async (ingrediensliste: string, prompt: string, imageUrl: string | undefined, logId: string | undefined): Promise<SuggestionDto> => {
        const payload = {
            ingrediensliste: ingrediensliste, prompt: prompt, imageUrl: imageUrl, logId: logId
        }
        const response = await axios.post(getBaseUrl() + '/ingrediensvask', payload);
        return response.data;
    },
    allergenmap: async (logId: string, ingrediensliste: string, prompt: string): Promise<string> => {
        const payload = {
            logId, ingrediensliste: ingrediensliste, prompt: prompt
        }
        const response = await axios.post(getBaseUrl() + '/allergenmapping', payload);
        return response.data;
    },
    sendKommentar: async (logId: string, kommentar: string, username: string) => {
        const payload = {
            logId, kommentar, username
        }
        const response = await axios.post(getBaseUrl() + '/kommentar', payload);
        return response.data;
    },
    sendThumbsUp: async (logId: string, thumbsUp: boolean, username: string) => {
        const payload = {
            logId, thumbsUp, username
        }
        const response = await axios.post(getBaseUrl() + '/thumbsUp', payload);
        return response.data;
    },
    getTilbakemeldinger: async (filter: TilbakeMeldingerFilter): Promise<AISessionEntity[]> => {
        let baseurl = getBaseUrl() + '/tilbakemeldinger';

        let conditions = [];

        if (filter.feedbackfilter) {
            conditions.push(`feedback=${filter.feedbackfilter}`);
        }

        if (filter.kommentarFilter) {
            conditions.push(`comment=${filter.kommentarFilter}`);
        }

        if (filter.searchText) {
            conditions.push(`&searchtext=${filter.searchText}`);
        }

        const url = baseurl + "?" + conditions.join('&');

        const response = await axios.get(url);
        return response.data;
    },
    uploadImage: async (file: File): Promise<ImageUploadResponse> => {
        const formData = new FormData();
        formData.append('file', file);

        const response = await axios.post(getBaseUrl() + '/ingredients/extractingredientsfromimage', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    },    
    feilmerking: {
        get: async (): Promise<Feilmerking[]> => {
            const response = await axios.get(getBaseUrl() + '/feilmerkinger');
            return response.data;
        },
        post: async (feilmerking: CreateFeilmerkingCommand) => {
            const response = await axios.post(getBaseUrl() + '/feilmerkinger', feilmerking);
            return response.data;
        },
        delete: async (feilmerkingId: string) => {
            const url = `${getBaseUrl()}/feilmerkinger/${feilmerkingId}`;
            const response = await axios.delete(url);
            return response.data;
        },        
        findGtinInImage: async (file: File): Promise<string> => {
            const url = `${getBaseUrl()}/feilmerkinger/findGtinInImage`;
            const formData = new FormData();
            formData.append('file', file);
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        },
        compareIngredients: async (feilmerkingId: string, file: File): Promise<any> => {
            const url = `${getBaseUrl()}/feilmerkinger/${feilmerkingId}/compareIngredients`;
            const formData = new FormData();
            formData.append('file', file);
            const response = await axios.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            return response.data;
        },
        toogleNeedFollowUp: async (feilmerkingId: string): Promise<any> => {
            const url = `${getBaseUrl()}/feilmerkinger/${feilmerkingId}/toogleNeedFollowUp`;            
            const response = await axios.post(url);
            return response.data;
        }
    }
};

export default EinsteinApi;