export interface TilbakeMeldingerFilter {
    feedbackfilter: string | undefined;
    kommentarFilter: string | undefined;
    searchText: string | undefined;
}

export interface SuggestionDto {
    ingredientSuggestion: string;
    comments: string;
    logId: string;
}

export interface AISessionEntity {
    allergenMappingDurationInMilliSeconds: number;
    ingrediensVaskDurationInMilliSeconds: number;
    bildeOpplastingDurationInMilliSeconds: number;
    allergenOutput: string;
    id: string;
    input: string;
    output: string;
    kommentar: string;
    username: string;
    thumbsUp: boolean | null;
    aiPrompt: string;
    feedbackType: string;
    partitionKey: string;
    rowKey: string;
    timestamp: number | null;
    tidspunkt: string;
    imageUrl?: string;
    ingrediensVaskDeserializedFailed: boolean;
    allergenDeserializedFailed: boolean;
}

export interface ImageUploadResponse {
    foundIngredients: boolean;
    ingredients: string;
    imageUrl: string | undefined;
    logId: string | undefined;
}

export enum ImageUploadStatus {
    Init,
    Uploading,
    SuccessIngredientsAndGtinFound,
    SuccessIngredientsFound,
    SuccessGtinFound,
    NoResultsFound,
    Failed
}

export interface Feilmerking {
    typeId: string
    gtin: string
    gtinExistInEpd: boolean
    imageUrl: any
    ingredientsComparedAt: any
    needsFollowUp: boolean
    title: string
    comment: any
    createdAt: string
    products: Product[]
    id: string
    documentType: string
    type: string
    typeName: string
    environment: string
    eTag: string
    timeToLive: number
    timestamp: number
    ingredientsFromImage: string | undefined
}

export interface Product {
    name: string
    epdNummer: string
    owner: string
    ingredients: string
    compareResult: any
}

export interface CreateFeilmerkingCommand {
    gtin: string
    gtinExistInEpd: boolean
    title: string
    products: {
        name: string
        epdNummer: string
        owner: string
        ingredients: string
    }[]
}

