import { Outlet, useLocation } from "react-router";
import MainMenu from "./components/MainMenu";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { Nav, Row } from "react-bootstrap";
import EnsureAuthToken from "../Shared/Auth/EnsureAuthToken";
import SidebarWrapper from "./components/Sidebar/SidebarWrapper";
import { IcebergIcon } from "@tradesolution/iceberg-ui-react";
import { NavLink2 } from "./components/Sidebar";
import { Link } from "react-router-dom";
import "./Layout.scss";

const Layout = () => {

    const location = useLocation();

    // redirect to login if not authenticated
    useMsalAuthentication(InteractionType.Redirect);

    const navLinks: NavLink2[] = [
        { label: 'Ingrediensliste-forbedrer', link: '/', icon: 'settings' },
        { label: 'Tilbakemeldinger', link: '/tilbakemeldinger', icon: 'comment', roles: ["UberAdmin", "TradesolutionAnsatt"] },
        { label: 'Feilmerking', link: '/feilmerking', icon: 'management', roles: ["UberAdmin", "TradesolutionAnsatt"] }

    ];

    return (
        <div className="d-flex flex-column min-vh-100">
            <AuthenticatedTemplate>
                <EnsureAuthToken>
                    <>
                        <MainMenu />
                        <div className="container-fluid mb-5 flex-grow-1">
                            <Row className="flex-nowrap">
                                <SidebarWrapper />
                                <main className="col">
                                    <Outlet />
                                </main>
                            </Row>
                        </div>
                        <div className="d-sm-none sticky-bottom fixed-bottom p-3 border-top" style={{ backgroundColor: 'white' }}>
                            <ul className="nav nav-pills nav-justified">
                                {navLinks.map(l => (
                                    <li key={l.link} className="nav-item">
                                        <Nav.Link title={l.label} as={Link} aria-label={l.label} to={l.link} className={l.link === location.pathname ? "active" : ""}>
                                            <IcebergIcon icon={l.icon as any} />
                                        </Nav.Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </>
                </EnsureAuthToken>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <p>Redirecting to login...</p>
            </UnauthenticatedTemplate>
        </div>


    );
}

export default Layout;