import { useEffect, useRef, useState } from "react";
import { Accordion, Badge, Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import EinsteinApi from "../../Services/EinsteinApi";
import EpdApi from "../../Services/EpdApi";
import { ProduktResponse } from "../../Services/EpdApi/models";
import { Feilmerking, CreateFeilmerkingCommand, Product } from "../../Services/EinsteinApi/model";
import useToaster from "../../Components/Toaster/useToaster";
import FileUploader from "./FileUploader";
import { IconButton } from "@tradesolution/iceberg-ui-react";
import { format } from "date-fns";
import CopyToClipboardIcon from "../../Components/CopyToClipboardIcon";
import FeilmerkingHubConnection from "./FeilmerkingHubConnection";
import { forEach } from "lodash";

const FeilmerkingPage = () => {

    const toaster = useToaster();
    const [visibleIndexImage, setVisibleIndexImage] = useState<number>(-1);

    //opplastet bilder
    const [uploadedImages, setUploadedImages] = useState<{ [key: string]: boolean }>({});
    const [uploadingImages, setUploadingImages] = useState<{ [key: string]: boolean }>({});
    const [uploadingImagesWithGtin, setUploadingImagesWithGtin] = useState<boolean>(false);

    FeilmerkingHubConnection().onFeilMerkingerUpdated = () => {
        loadFeilmerkinger();
    };

    const loadFeilmerkinger = async () => {
        const result = await EinsteinApi.feilmerking.get();
        setFeilmerkinger(result);
    }

    useEffect(() => {
        loadFeilmerkinger();
    }, []);

    // fyres når nytt bilde velges i file input

    const handleImageUpload = async (file: File, feilmerking: Feilmerking): Promise<void> => {
        setUploadingImages(prevState => ({ ...prevState, [feilmerking.id]: true }));  // <- Set  uploading image state to true
        toaster.info("Laster opp bilde", "Vennligst vent. Bilde lastes opp");
        await EinsteinApi.feilmerking.compareIngredients(feilmerking.id, file);
        toaster.success("Bilde lastet opp", "Bilde lastet opp og prossessert");
        setUploadedImages(prevState => ({ ...prevState, [feilmerking.id]: true }));
        setUploadingImages(prevState => ({ ...prevState, [feilmerking.id]: false }));  // <- Set  uploading image state to false
        await loadFeilmerkinger();
    };

    const getBasisPakning = (gtin: number, produktResponse: ProduktResponse): any => {
        // finn første pakning i respons der gtin stemmer med input parameter
        for (const element of produktResponse) {
            if (element.Pakning.Basispakning && element.Pakning.GTIN === gtin) {
                return element.Pakning.Basispakning;
            }
            for (const pakninglvl1 of element.Pakning.Pakninger) {
                if (pakninglvl1.Basispakning && pakninglvl1.GTIN === gtin) {
                    return pakninglvl1.Basispakning;
                }
                for (const pakninglvl2 of pakninglvl1.Pakninger) {
                    if (pakninglvl2.Basispakning && pakninglvl2.GTIN === gtin) {
                        return pakninglvl2.Basispakning;
                    }
                    for (const pakninglvl3 of pakninglvl2.Pakninger) {
                        if (pakninglvl3.Basispakning && pakninglvl3.GTIN === gtin) {
                            return pakninglvl3.Basispakning;
                        }
                        for (const pakninglvl4 of pakninglvl3.Pakninger) {
                            if (pakninglvl4.Basispaknin && pakninglvl4.GTIN === gtin) {
                                return pakninglvl4.Basispakning;
                            }
                        }
                    }
                }
            }
        }
    }

    const getIngredients = (gtin: number, produktResponse: ProduktResponse): string | undefined => {
        const basisPakning = getBasisPakning(gtin, produktResponse);
        return basisPakning?.Umerketvariant[0].Ingredienser;
    }

    const lookUpGtinInEpd = async (gtin: string | undefined): Promise<Produkt[]> => {
        if (gtin) {
            const result = await EpdApi.getProdukt(gtin);

            if (result.length === 0) {
                toaster.error("Feilmelding", "Fant ingen pakninger for gtin " + gtin)
            }
            else {

                return result.map(produkt => ({
                    epdNr: produkt.EPDNr.toString(),
                    navn: produkt.Pakning.Markedsnavn,
                    owner: produkt.Produkteier.Produkteiernavn,
                    ingredients: getIngredients(parseInt(gtin), result)
                }));
            }
        }

        return [];
    }

    const [gtinInput, setGtinInput] = useState<string>();

    const handleGtinFound = async (gtin: string) => {
        setGtinInput(gtin);
    }

    // et gyldig gtin er 13 tegn lagt så fyr oppslag i EPD når gtinInput endres
    useEffect(() => {
        if (gtinInput && gtinInput.length === 13) {
            addFeilmerking(gtinInput);
        }
    }, [gtinInput]);

    interface Produkt {
        epdNr: string;
        navn: string;
        owner: string;
        ingredients: string | undefined;
    }

    const [feilmerkinger, setFeilmerkinger] = useState<Feilmerking[]>([]);

    const addFeilmerking = async (gtin: string | undefined) => {
        // add gtin to gtinListe
        if (gtin) {
            // slå opp i EPD
            const produkter = await lookUpGtinInEpd(gtin);

            const command: CreateFeilmerkingCommand = {
                gtin: gtin,
                gtinExistInEpd: produkter.length > 0,
                title: gtin,
                products: produkter.map(produkt => ({
                    name: produkt.navn,
                    epdNummer: produkt.epdNr,
                    owner: produkt.owner,
                    ingredients: produkt.ingredients ?? ''
                }))
            }

            await EinsteinApi.feilmerking.post(command);
            setGtinInput("");
            toaster.success("Lagt til", "Feilmerkingen registrert");
            await loadFeilmerkinger();
        }
    }

    const deleteFeilmerking = async (e: any, feilmerking: Feilmerking) => {
        e.preventDefault();
        e.stopPropagation();
        await EinsteinApi.feilmerking.delete(feilmerking.id);
        toaster.success("Slettet", "Feilmerkingen er slettet");
        await loadFeilmerkinger();
    }

    const toogleNeedFollowUp = async (e: any, feilmerking: Feilmerking) => {
        e.preventDefault();
        e.stopPropagation();
        await EinsteinApi.feilmerking.toogleNeedFollowUp(feilmerking.id);
        toaster.success("Oppfølging", "Status endret for " + feilmerking.title);
        await loadFeilmerkinger();
    }


    const getStatusBadge = (feilmerking: Feilmerking) => {
        if (feilmerking.products.length === 0) {
            return <Badge className="badge bg-danger text-dark">Ingen EPD info</Badge>
        }
        if (feilmerking.needsFollowUp) {
            return <Badge className="badge bg-warning text-dark">Trenger oppfølging</Badge>
        } else {
            return <Badge className="badge bg-success">Behandlet</Badge>
        }
    };

    const findGtinFromImage = async (file: File) => {
        setUploadingImagesWithGtin(true);
        const result = await EinsteinApi.feilmerking.findGtinInImage(file);
        if (result) {
            let gtinArray = result.split(',');
            for (const gtin of gtinArray) {
                await addFeilmerking(gtin);
            }
        }
        else {
            toaster.error("Ingen GTIN", "Fant ikke GTIN i bildet. Prøv å ta et nytt bildet hvor strekkoden er synlig");
        }
        setUploadingImagesWithGtin(false);
    };

    const getQuickAction = (feilmerking: Feilmerking) => {
        const isImageUploaded = !!feilmerking.imageUrl;
        const isUploading = !!uploadingImages[feilmerking.id];

        return (
            <div className="d-flex align-items-center ml-auto">
                {feilmerking.products.length === 0 && (
                    <IconButton size="sm" icon="trash" variant="outline-primary" onClick={e => deleteFeilmerking(e, feilmerking)}>Slett</IconButton>
                )}
                {feilmerking.products.length > 0 && (
                    <div className="ml-3">
                        <FileUploader
                            handleFile={(file: File) => handleImageUpload(file, feilmerking)}
                            isImageUploaded={isImageUploaded}
                            uploading={isUploading}
                        />
                    </div>
                )}
            </div>
        );
    };

    //tester fargekode for å vise forskjeller
    function replaceLineBreaks(text: string) {
        return text.replace(/\n/g, '<br />');
    }

    return (
        <>
            <h1 className="mt-3">Feilmerking</h1>
            <p className="alert alert-primary">
                Registrer gtins som skal sjekkes for feil i ingrediensene
            </p>

            <div>
                <InputGroup className="mb-3">
                    <div className="input-group-prepend">
                        <FileUploader
                            title="Find GTIN i bilde"
                            handleFile={(file: File) => findGtinFromImage(file)}
                            isImageUploaded={false}
                            uploading={uploadingImagesWithGtin} ></FileUploader>
                    </div>
                    <Form.Control
                        style={{ padding: "6px 20px" }}
                        type="text"
                        placeholder="Legg inn gtin, enten manuelt eller fra et bilde (kan inneholde flere GTIN) eller strekkodeleser"
                        value={gtinInput}
                        onChange={(e) => setGtinInput(e.target.value)}
                        autoFocus
                    />
                    <div className="input-group-append">
                        <Button onClick={() => addFeilmerking(gtinInput)}>Legg til</Button>
                    </div>
                </InputGroup>
            </div>
            <Accordion className="mt-4">
                {feilmerkinger.map((feilmerking, index) => (
                    <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>
                            <div className="d-flex w-100 justify-content-between align-items-center">
                                <div>
                                    <div className="d-flex align-items-center">
                                        <div className="ms-3">{getStatusBadge(feilmerking)}</div>
                                    </div>
                                    {feilmerking.products.length > 0 ? feilmerking.products[0].name : "Ingen produktnavn"} - <small className="text-body-secondary">{feilmerking.gtin} - {feilmerking.products.length} stk</small>
                                </div>
                                <div>
                                    {getQuickAction(feilmerking)}
                                </div>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col>
                                        <div className="d-flex justify-content-end gap-2">
                                            <small>{format(new Date(feilmerking.createdAt), "dd.MM.yy hh:mm:ss")}</small>
                                            <Button size="sm" variant="outline-primary" onClick={(e) => toogleNeedFollowUp(e, feilmerking)}>{feilmerking.needsFollowUp ? 'Ferdig behandlet' : 'Trenger behandling'}</Button>
                                            <Button size="sm" variant="outline-primary" onClick={(e) => deleteFeilmerking(e, feilmerking)}>Slett</Button>
                                        </div>
                                    </Col>
                                </Row>
                                {feilmerking.products.length === 0 ? (
                                    // Hvis det ikke finnes noen produkter i feilmerking.products.
                                    <p style={{ color: "red" }}>Fant ingen ingredienser i EPD, vennligst prøv igjen.</p>
                                ) : (
                                    feilmerking.products.map(produkt => (
                                        <div key={produkt.epdNummer}>
                                            <strong>{produkt.name} - {produkt.epdNummer}, {produkt.owner}</strong>
                                            <h5 className="mt-3">Ingredienser fra EPD</h5>
                                            {produkt.ingredients && produkt.ingredients.trim() !== '' && (
                                                <>
                                                    {produkt.ingredients}
                                                    <CopyToClipboardIcon val={produkt.ingredients} />
                                                </>
                                            )}

                                            {feilmerking.ingredientsFromImage && (
                                                <>
                                                    <div className="row">
                                                        <div className="col">
                                                            <h5 className="mt-3">Ingredienser fra pakning</h5>
                                                        </div>
                                                    </div>

                                                    {feilmerking.ingredientsFromImage}
                                                    <CopyToClipboardIcon val={feilmerking.ingredientsFromImage} />

                                                </>
                                            )}

                                            {produkt.compareResult && (
                                                <>
                                                    <h5 className="mt-3">Sammenligning</h5>
                                                    <div dangerouslySetInnerHTML={{ __html: replaceLineBreaks(produkt.compareResult) }} />
                                                    <CopyToClipboardIcon val={produkt.compareResult} />
                                                </>
                                            )}

                                            <br />
                                            {feilmerking.imageUrl && <Button variant="outline-primary" onClick={() => setVisibleIndexImage(visibleIndexImage === index ? -1 : index)}>{visibleIndexImage === index ? "Skjul bilde" : "Se bilde"}</Button>}
                                            {index === visibleIndexImage && feilmerking.imageUrl && (
                                                <div className="mt-3">
                                                    <img src={feilmerking.imageUrl} alt="bilde" style={{ maxWidth: "700px" }} /><br /><br />
                                                </div>
                                            )}
                                            <hr />
                                        </div>
                                    )))}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </>);

}

export default FeilmerkingPage;