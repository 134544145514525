import React from 'react';
import Style from './index.module.css';
import { Alert, Button } from 'react-bootstrap';
import { Toast } from './types';
import { IcebergIcon, IcebergIconProps } from '@tradesolution/iceberg-ui-react';

interface Props {
  toasts: Toast[];
  handleCloseToast: any;
  onCancelRequest?: () => void;
}

const Toaster: React.FC<Props> = props => {

  const handleCancelRequest = () => {
    if (props.onCancelRequest)
      props.onCancelRequest();
  }

  const getIcon = (type: string): string => {
    switch (type) {
      case "success":
        return "check";
      case "danger":
        return "exclamationMark";
      case "warning":
        return "alert";
      case "info":
        return "info";
      default:
        return "info";
    } 
  }

  return (
    <div className={Style.toastContainer}>
      {props.toasts &&
        props.toasts.map((t, index) => {
          return (
            <Alert
              key={`alert-${Math.random()}`}
              show={true}
              variant={t.type}
              onClose={() => props.handleCloseToast(index)}
              dismissible={t.dismissible}
            >
              <div className="alert-left">
                <div className="alert-icon">
                  <IcebergIcon icon={getIcon(t.type) as any} />
                </div>
              </div>
              <div className='alert-center'>
                <p>{t.message || ''}</p>
              </div>   
            </Alert>
          );
        })}
    </div>
  )
};
export default Toaster;