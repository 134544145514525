import { IPublicClientApplication } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import {
    IcebergIcon,
    IcebergProductMenu,
    IcebergCustomerPicker,
    IcebergNotifications
} from '@tradesolution/iceberg-ui-react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { ReactComponent as AppLogo } from './app.icon.svg';
import { useEffect } from 'react';
import LogRocket from 'logrocket';
import { loginRequest } from '../../../Shared/Auth/authConfig';

const MainMenu = () => {

    const { instance, accounts } = useMsal();
    const name = accounts[0] && accounts[0].name;

    const getFirstName = (fullname: string | undefined): string => {
        if (!fullname) {
            return "";
        }
        return fullname.split(' ')[0];
    };

    const handleLogin = (i: IPublicClientApplication) => {
        i.loginRedirect(loginRequest).catch(e => {
            console.error(e);
        });
    }

    const handleLogout = () => {
        instance.logoutRedirect().catch(e => {
            console.error(e);
        });
    }

    //for logrocket 
    useEffect(() => {
        LogRocket.identify(name ?? '', undefined);
    }, [name]);

    return <Navbar collapseOnSelect expand="lg" bg='info-dark' sticky='top' style={{ padding: "0.2rem 0" }}>
        <Container fluid>
            <Navbar.Brand href="/">
                <AppLogo style={{ height: "34px", width: "34px" }} />
                <span style={{ marginLeft: "1rem" }}>Einstein</span>
            </Navbar.Brand>
            <div style={{ color: "#fff" }}>
                <Navbar.Toggle aria-controls="navbarScroll" />
            </div>
            <Navbar.Collapse role="navigation">
                <Nav className="me-auto">
                    <NavDropdown className='noCaret'
                        title={<IcebergIcon icon='meatballs' />}
                    >
                        <IcebergProductMenu />
                    </NavDropdown>
                </Nav>
                <Nav>
                    <NavDropdown
                        id="dropdownUserMenu"
                        align="end"
                        title={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginLeft: '0.5em' }}>{getFirstName(name)}</span>
                            </div>
                        }
                    >
                        <NavDropdown.Item onClick={handleLogout}>
                            <span>
                                <strong>Logg av</strong>
                            </span>
                        </NavDropdown.Item>
                    </NavDropdown>
                    <IcebergCustomerPicker />
                    <IcebergNotifications />
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>
};

export default MainMenu;