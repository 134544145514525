import React, { useState } from "react";
import AiApi from "./Api";

const getAIResponse = async (prompt: string) => {
    const response = await AiApi.ask(prompt);
    return response;
}

const SmartPastePage = () => {
    const [formData, setFormData] = useState({
        email: '',
        fornavn: '',
        etternavn: '',
        ansatt: '',
        altEmail: '',
        tlf: '',
        tittel: '',
    });

    const handleInputChange = (field: string, value: string) => {
        setFormData(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const createAIPrompt = (text: string) => {
        return `
        Extract the following contact information from the text:
        - Email
        - First Name
        - Last Name
        - Company/Organization
        - Alternative Email
        - Phone Number
        - Title/Position

        The input text is: "${text}"
        
        Return the information in an array in this specific order:
        [email, fornavn, etternavn, ansatt, altEmail, tlf, tittel].
        Only respond in valid json format.
        `;
    };

    const handleSmartPaste = async () => {
        try {
            const text = await navigator.clipboard.readText();
            console.log("Clipboard text: ", text); // Log clipboard text

            // Create AI prompt
            const aiPrompt = createAIPrompt(text);
            console.log("AI Prompt: ", aiPrompt);

            // Get AI response 
            const aiResponse = await getAIResponse(aiPrompt);
            console.log("AI Response: ", aiResponse);

            // Parse the AI response
            if (typeof(aiResponse) === "string") {
                console.error(aiResponse);
            } else {
                const [email, fornavn, etternavn, ansatt, altEmail, tlf, tittel] = aiResponse;

                setFormData({
                    email,
                    fornavn,
                    etternavn,
                    ansatt,
                    altEmail,
                    tlf,
                    tittel,
                });
    
                console.log("Updated formData: ", { email, fornavn, etternavn, ansatt, altEmail, tlf, tittel });
            }
            

            
        } catch (err) {
            console.error('Failed to read clipboard contents: ', err);
        }
    };


    return (
        <div className="container mt-5">
            <h2>Opprett ny bruker</h2>
            <form>
                <div className="row mb-3">
                    <div className="col">
                        <label>E-post</label>
                        <input type="email" className="form-control" value={formData.email} onChange={e => handleInputChange('email', e.target.value)} />
                    </div>
                    <div className="col">
                        <label>Fornavn</label>
                        <input type="text" className="form-control" value={formData.fornavn} onChange={e => handleInputChange('fornavn', e.target.value)} />
                    </div>
                    <div className="col">
                        <label>Etternavn</label>
                        <input type="text" className="form-control" value={formData.etternavn} onChange={e => handleInputChange('etternavn', e.target.value)} />
                    </div>
                </div>
                <div className="mb-3">
                    <label>Ansatt hos TsKunde</label>
                    <input type="text" className="form-control" value={formData.ansatt} onChange={e => handleInputChange('ansatt', e.target.value)} />
                </div>
                <div className="row mb-3">
                    <div className="col">
                        <label>Alternativ e-post</label>
                        <input type="email" className="form-control" value={formData.altEmail} onChange={e => handleInputChange('altEmail', e.target.value)} />
                    </div>
                    <div className="col">
                        <label>Telefon</label>
                        <input type="text" className="form-control" value={formData.tlf} onChange={e => handleInputChange('tlf', e.target.value)} />
                    </div>
                    <div className="col">
                        <label>Tittel</label>
                        <input type="text" className="form-control" value={formData.tittel} onChange={e => handleInputChange('tittel', e.target.value)} />
                    </div>
                </div>
                <button type="button" className="btn btn-primary" onClick={handleSmartPaste}>Smart Paste</button>
                <button type="submit" className="btn btn-success ml-3">Submit</button>
            </form>
        </div>
   
    );

};

export default SmartPastePage;