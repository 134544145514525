import { useEffect, useState } from "react";
import Sidebar, { NavLink2 } from ".";
import { Permission } from "./KundeportalenApi/models";
import KundeportalenApi from "./KundeportalenApi";

const SidebarWrapper = () => {
    const navLinks: NavLink2[] = [
        { label: 'Ingrediensliste-forbedrer', link: '/', icon: 'settings' },
        { label: 'Tilbakemeldinger', link: '/tilbakemeldinger', icon: 'comment', roles: ["UberAdmin", "TradesolutionAnsatt"] },
        { label: 'Feilmerking', link: '/feilmerking', icon: 'management', roles: ["UberAdmin", "TradesolutionAnsatt"] }
    ];

    const [permissions, setPermissions] = useState<Permission[]>([]);

    const loadPermissions = async () => {
        const data = await KundeportalenApi.getPermissions();
        setPermissions(data);
    }

    useEffect(() => {
        loadPermissions();
    }, []);

    const [filteredNavLinks, setFilteredNavLinks] = useState<NavLink2[]>([]);

    useEffect(() => {
        if (permissions.length === 0) {
            setFilteredNavLinks(navLinks.filter(o => !o.roles));
        }
        else {
            setFilteredNavLinks(navLinks.filter(o => !o.roles || o.roles.some(r => permissions.some(p => p.role === r))));
        }
    }, [permissions]);

    return <Sidebar navLinks={filteredNavLinks} />
}

export default SidebarWrapper;