import {
    ReactNode,
} from 'react';

interface OnClickWrapperProps {
    onClick: () => void;
    children: ReactNode;
}

// This is only temporary - since Iceberg design doesn't have a clickable icon,
// and IcebergIcon have had onClick removed. Should be used as a button instead?
export const OnClickWrapper = (props: OnClickWrapperProps) => {
    return (
        <div
            style={{
                display: 'inline-block',
                padding: 0,
                margin: 0,
            }}
            onClick={props.onClick}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    props.onClick();
                }
            }}
            onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    props.onClick();
                }
            }}
        >
            {props.children}
        </div>
    );
};