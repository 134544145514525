import { useState } from "react";
import AiApi from "../SmartSummary/Api";
import parse from 'html-react-parser';

const getAIResponse = async (prompt: string) => {
    const response = await AiApi.summarize(prompt);
    return response;
}

const SmartSummaryPage = () => {
    const [emailText, setEmailText] = useState<string>("");
    const [summary, setSummary] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const createAIPrompt = (text: string) => {
        return `
            Summarize the following text:

            Input Text: "${text}"
            
            Provide the summary in plain text, written in Norwegian.

            Focus on key details such as customer inquiries, important questions, or issues mentioned in the email.

            First, identify and list the main category or theme of the email, such as "Problemer med kontotilgang", "Spørsmål om fakturering", etc.

            Format the response as follows:

            Category: [Identified Category] [line break]

            Summary: [Provide the summary here, with a clear separation from the category.]
        `;
    };

    const handleSmartSummary = async () => {
        try {
            setLoading(true); // Start loading
            // Create AI prompt
            const aiPrompt = createAIPrompt(emailText);
            console.log("AI Prompt: ", aiPrompt);

            // Get AI response 
            const aiResponse = await getAIResponse(aiPrompt);
            console.log("AI Response: ", aiResponse);

            // Update state with summary
            if (typeof (aiResponse) === "string") {
                setSummary(aiResponse.replace(/(?:\r\n|\r|\n)/g, '<br/>'));
            } else {
                setSummary("Failed to generate summary.");
            }
        } catch (err) {
            console.error('Failed to generate summary: ', err);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <div className="container mt-5">
            <h2>Smart Summary</h2>
            <form>
                <div className="mb-3">
                    <label>Email Text</label>
                    <textarea
                        className="form-control"
                        value={emailText}
                        onChange={e => setEmailText(e.target.value)}
                        rows={10}
                    ></textarea>
                </div>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSmartSummary}
                    disabled={loading} // Disable button when loading
                >
                    Smart Summary
                </button>
            </form>
            {loading && (
                <div className="mt-2">
                    <p>Loading Please wait...</p>
                </div>
            )}
            {summary && (
                <div className="mt-4">
                    <h3>Summary</h3>
                    <p>{parse(summary)}</p>
                </div>
            )}
        </div>
    );
}

export default SmartSummaryPage;