import { IconButton } from "@tradesolution/iceberg-ui-react";
import { ChangeEvent, useEffect, useState } from "react";
import { Alert, Button, Col, Collapse, Form, Row } from "react-bootstrap";
import EinsteinApi from "../../Services/EinsteinApi";
import SpinnerWithText from "../../Components/SpinnerWithText";
import './index.scss'
import HTMLReactParser from "html-react-parser";
import KundeportalenApi from "../../Services/KundeportalenApi";
import { Permission } from "./Permissions";
import { useMsal } from "@azure/msal-react";
import { useSearchParams } from "react-router-dom";
import useToaster from "../../Components/Toaster/useToaster";
import CopyToClipboardIcon from "../../Components/CopyToClipboardIcon";
import { ImageUploadResponse, ImageUploadStatus } from "../../Services/EinsteinApi/model";

interface AllergenMap {
    ingrediens: string;
    allergen: {
        overliggende: string;
        underliggende: string;
    } | string;
}

const emphasizeWords = (text: string, words: string[], tag: string = 'strong') => {
    // Escape special characters in the words to avoid issues in the regex
    const escapedWords = words.map(word => word.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'));

    // Create a regex to match the words case-insensitively and avoid partial matches
    const regex = new RegExp(`\\b(${escapedWords.join('|')})\\b`, 'gi');

    // Replace the found words with themselves wrapped in the specified tag
    return text.replace(regex, `<${tag}>$1</${tag}>`);
}

const IngredienserPage = () => {

    const { accounts } = useMsal();
    const toaster = useToaster();

    const navnInnloggetBruker = accounts[0] && accounts[0].name;

    const [searchParams, setSearchParams] = useSearchParams();

    const ingredienserFromQuerystring = searchParams.get('ingredienser');
    const exampleText = "MELK, 30% TORSK* (Gadus morhua) (FISK) fanget i det nordøstlige Atlanterhavet (FAO27), makaroni (DurumHVETE og vann), saus (modifisert stivelse (E 1422), maltodekstrin (mais), EGG, salt, løk, naturlig aroma, gulrot, modifisert stivelse (E 1450), muskatnøtt, pepper, gurkemeie, smøraroma), vann, brødsmuler (HVETE, gjær, salt, antiklumpemiddel (E 535)), rapsolje.";
    const [ingrediensListe, setIngrediensListe] = useState<string>(ingredienserFromQuerystring ?? exampleText)
    const [vaskerIngrediensListe, setVaskerIngrediensListe] = useState(false);
    const [correctedIngrediensListe, setCorrectedIngrediensListe] = useState<string | undefined>();
    const [correctedIngrediensListEmphasized, setCorrectedIngrediensListeEmphasized] = useState<string | undefined>();
    const [comment, setComment] = useState<string | undefined>();

    const [errorText, setErrorText] = useState<string | undefined>();

    const [finnerAllergener, setFinnerAllergener] = useState(false);
    const [allergenMapping, setAllergenMapping] = useState<AllergenMap[]>([]);

    const defaultPrompt = `
    You are an ingredient specialist that help improving quality in retail ingredient lists. Check the ingredient list for typos, formatting errors and remove elements that are not typical ingredients. Also remove text related to origin of an ingredient or text saying something is ecological etc. Return a proposal, to a corrected comma separated ingredients list with proper casing on the letters. Translate ingredients to Norwegian unless it is written in Swedish or Danish. Do not remove alternative languages if they are Swedish or Danish. Please provide comments in Norwegian for all corrections. Please return the result in a valid JSON object like this: {\"ingredientSuggestion\": \"\", \"comments\": \"\"}. Remember to escape any quotes that are not part of the JSON. Here are two examples:

Example 1:
MELK, 30% TORSK* (Gadus morhua) (FISK) fanget i det nordøstlige Atlanterhavet (FAO27), makaroni (DurumHVETE og vann), saus (modifisert stivelse (E 1422), maltodekstrin (mais), EGG, salt, løk, naturlig aroma, gulrot, modifisert stivelse (E 1450), muskatnøtt, pepper, gurkemeie, smøraroma), vann, brødsmuler (HVETE, gjær, salt, antiklumpemiddel (E 535)), rapsolje.

{
"ingredientSuggestion": "Melk, 30% torsk* (Gadus morhua) (fisk), makaroni (durumhvete og vann), saus (modifisert stivelse (E 1422), maltodekstrin (mais), egg, salt, løk, naturlig aroma, gulrot, modifisert stivelse (E 1450), muskatnøtt, pepper, gurkemeie, smøraroma), vann, brødsmuler (hvete, gjær, salt, antiklumpemiddel (E 535)), rapsolje",
"comments": ""MELK" bør skrives med små bokstaver."
}

Example 2:
Aqua, Cocamidopropyl Betaine, Sodium Lauroyl Isethionate, Glycerin, Sodium Benzoate, Parfum, Sodium Chloride, Carbomer, Glycol Distearate, Caprylyl Glycol, Lauric Acid, Sodium Hydroxide, Stearic Acid, Sodium Lauroyl Glycinate, Sodium Isethionate, Palmitic Acid, PPG-6, PEG-150 Pentaerythrityl Tetrastearate, Sodium Gluconate, PPG-2 Hydroxyethyl Cocamide, Cucumis Sativus Juice, Camellia Sinensis Leaf Extract, Alpha-Isomethyl Ionone, Hexyl Cinnamal, Linalool, CI 19140, CI 42090.

{
"ingredientSuggestion": "Vann, cocamidopropyl betain, natrium lauroyl Isethionate, glycerin, natrium benzoat, parfyme, natriumklorid, karbomer, glykol distearate, kaprylyl glykol, laurinsyre, natriumhydroksid, stearinsyre, natrium lauroyl glycinate, natrium isethionate, palmitinsyre, PPG-6, PEG-150 pentaerythrityl tetrastearate, natrium glukonat, PPG-2 hydroxyethyl cocamide, agurkjuice, grønn te-blad ekstrakt, alpha-isomethyl ionone, hexyl cinnamal, linalool, CI 19140, CI 42090.",
"comments": "Ingen stavefeil, formatteringsfeil eller uvanlige ingredienser ble funnet i denne ingredienslisten. Alle ingrediensene er skrevet på engelsk, så jeg vil oversette dem til norsk."
}
`;

    const [ingrediensVaskPrompt, setIngrediensVaskPrompt] = useState<string>(defaultPrompt);

    const [allergenmappingPrompt, setAllergenMappingPrompt] = useState<string>(
        `You are an allergen specialist and code assistant that always respond with a valid JSON format.
 Check the list of ingredients for potential allergens and return a mapping between the ingredient and allergen.
 Only check for allergens from this list (in Norwegian): Gluten, Hvete gluten, Rug gluten, Bygg gluten, Havre gluten, Spelt gluten, Bløtdyr, Egg, Fisk, Khorasanhvete gluten, Lupiner, Melk, Nøtter, Hasselnøtter, Kasjunøtter, Macademiannøtter, Mandler, Paranøtter, Peanøtter, Pekannøtter, Pistasienøtter, Valnøtter, Selleri, Sennep, Sesamfrø, Skalldyr, Soya, Svoveldioksid eller sulfitter.

 If the ingredients says gluten free oat, it should not be considered an allergen. Only keep parts of the ingredient that is relevant for allergens.
 I.e. helkorn havre should be havre, sammalt rug mellomgrov should be rug and tørket surdeig av hvete should be hvete. This should be returned as a JSON array of object “{ingrediens, allergen}”. Only reply with valid JSON, no other text. If there are no allergens, return JSON with an empty array. If an ingredient contains no allergens, it should not be returned. If the allergen is gluten or nuts, we would also like the underlying allergen. We want the JSON in Norwegian. Here are some examples of how we want the answer for a given ingredient list: 

Ingredients: Melk, 30% torsk (Gadus morhua) (fisk) fanget i det nordøstlige Atlanterhavet (FAO27), makaroni (durumhvete og vann), saus (modifisert stivelse (E 1422), maltodekstrin (mais), egg, salt, løk, naturlig aroma, gulrot, modifisert stivelse (E 1450), muskatnøtt, pepper, gurkemeie, smøraroma), vann, brødsmuler (hvete, gjær, salt, antiklumpemiddel (E 535)), rapsolje.

Answer:
[
    {
        "ingrediens": "Melk",
        "allergen":  "Melk"
    },
    {
        "ingrediens": "torsk",
        "allergen": "Fisk"
    },
    {
        "ingrediens": "durumhvete ",
        "allergen": {
            "overliggende": "Gluten",
            "underliggende": "Durumhvete"
        }
    },
    {
        "ingrediens": "egg",
        "allergen": "Egg"
    },
    {
        "ingrediens": "hvete",
        "allergen": {
            "overliggende": "Gluten",
            "underliggende": "Hvete"
        }
    }    
]

Ingredients: Pasteurisert melk, helkorn HAVRE, maltet BYGG, sammalt RUG

Answer:
[
    {
        "ingrediens": "Melk",
        "allergen":  "Melk"
    },
    {
        "ingrediens": "havre",
        "allergen": {
            "overliggende": "Gluten",
            "underliggende": "Havre"
    }
    },
    {
        "ingrediens": "bygg",
        "allergen": {
            "overliggende": "Gluten",
            "underliggende": "Bygg"
        }
    },
    {
        "ingrediens": "rug",
        "allergen": {
            "overliggende": "Gluten",
            "underliggende": "Rug"
        }
    }    
]`);

    //To control the visibility of the textfields
    const [showPromptFields, setShowPromptFields] = useState(false);
    const [isTradesolutionAnsatt, setIsTradesolutionAnsatt] = useState(false);


    //Ny state for kommentar og lagring
    const [kommentar, setKommentar] = useState<string>("");
    const [lagreData, setLagreData] = useState<boolean>(false);

    //alert
    const [show, setShow] = useState(true);

    //Function to change the visibility of the fields
    const changePromptFields = () => {
        setShowPromptFields(!showPromptFields);
    };

    const getPermissions = async () => {
        const result = await KundeportalenApi.getPermissions();

        const tradesolutionAnsattPermission = result.find(o => o.role === Permission.TradesolutionAnsatt && o.accessTo === "Global");
        const isUberAdmin = result.find(o => o.role === Permission.UberAdmin && o.accessTo === "Global");

        setIsTradesolutionAnsatt((!!tradesolutionAnsattPermission || !!isUberAdmin));
    };

    useEffect(() => {
        getPermissions();

        if (ingredienserFromQuerystring) {
            vaskIngrediensListe(ingrediensListe);
        }

    }, []);

    const [logId, setLogId] = useState<string | undefined>();

    const vaskIngrediensListe = async (ingredients: string) => {
        // reset results
        setCorrectedIngrediensListe(undefined);
        setCorrectedIngrediensListeEmphasized(undefined);
        setComment(undefined);
        setAllergenMapping([]);


        setVaskerIngrediensListe(true);
        try {
            const result = await EinsteinApi.ingrediensvask(ingredients, ingrediensVaskPrompt, imageUploadResponse?.imageUrl, imageUploadResponse?.logId);
            setCorrectedIngrediensListe(result.ingredientSuggestion);
            setComment(result.comments);
            setLogId(result.logId);
        }
        catch (e) {
            //setErrorText("Ingrediensvask feilet, vennligst prøv igjen senere!");
            toaster.error("Systemfeil", "Ingrediensvask feilet, vennligst prøv igjen senere!");
        }
        setVaskerIngrediensListe(false);

    };


    useEffect(() => {
        if (correctedIngrediensListe) {
            finnAllergener();
        }
    }, [correctedIngrediensListe]);

    const finnAllergener = async () => {
        if (!correctedIngrediensListe)
            return;

        setFinnerAllergener(true);
        try {
            const allergenMap = await EinsteinApi.allergenmap(logId ?? "", correctedIngrediensListe, allergenmappingPrompt);
            if (Array.isArray(allergenMap)) {
                setAllergenMapping(allergenMap);
                setCorrectedIngrediensListeEmphasized(emphasizeWords(correctedIngrediensListe, allergenMap.map((o: any) => o.ingrediens)))
            } else {
                setErrorText('Kunne ikke parse allegenmapping, se console for output!')
            }
        }
        catch (e) {
            setErrorText("Allergen oppslag feilet, vennligst prøv igjen senere!");
        }

        setFinnerAllergener(false);


    }

    const [kommentarSendt, setKommentarSendt] = useState(false);

    const sendKommentar = async () => {
        setLagreData(true);

        if (logId) {
            await EinsteinApi.sendKommentar(logId, kommentar, navnInnloggetBruker ?? 'ukjent');
        }

        setLagreData(false);
        setKommentarSendt(true);
    }

    const [thumbsUpSendt, setThumbsUpSendt] = useState(false);
    const [thumbsUpValue, setThumbsUpValue] = useState<string>();
    const sendThumbsUp = async (thumbsUp: boolean) => {
        setLagreData(true);

        if (logId) {
            await EinsteinApi.sendThumbsUp(logId, thumbsUp, navnInnloggetBruker ?? 'ukjent');
        }

        setLagreData(false);
        setThumbsUpSendt(true);
        setThumbsUpValue(thumbsUp.toString())
    }


    // skal file upload være synlig
    const [showImageUpload, setShowImageUpload] = useState(false);

    // toggle om file upload skal være synlig
    const toggleImageUpload = (e: any): void => {
        e.preventDefault();
        setShowImageUpload(!showImageUpload);
    }

    // holder opplastet bilde
    const [image, setImage] = useState<File | null>(null);

    // fyres når nytt bilde velges i file input
    const handleImageUpload = (event: ChangeEvent<any>): void => {
        setImage(event.target.files[0]);
    }

    // holder responsen fra bildeopplasting
    const [imageUploadResponse, setImageUploadResponse] = useState<ImageUploadResponse | undefined>();

    const [imageUploadStatus, setImageUploadStatus] = useState<ImageUploadStatus>(ImageUploadStatus.Init);

    // last opp bilde til api
    const uploadImage = async () => {
        if (!image) {
            return;
        }

        try {
            setImageUploadStatus(ImageUploadStatus.Uploading);
            const result = await EinsteinApi.uploadImage(image);
            setImageUploadResponse(result);

            if (!result.foundIngredients) {
                setImageUploadStatus(ImageUploadStatus.NoResultsFound);
            } else {
                setImageUploadStatus(ImageUploadStatus.SuccessIngredientsFound);
            }
        } catch (e) {
            setImageUploadStatus(ImageUploadStatus.Failed);
        }
    };

    // fyres når bilde endres
    useEffect(() => {
        if (image) {
            uploadImage();
        }
    }, [image]);

    // vi har fått respons på bildeopplasting
    useEffect(() => {
        if (imageUploadResponse && imageUploadResponse.foundIngredients) {
            setIngrediensListe(imageUploadResponse.ingredients);
            vaskIngrediensListe(imageUploadResponse.ingredients);
        }
    }, [imageUploadResponse]);

    const displayImageUploadStatus = () => {
        switch (imageUploadStatus) {
            case ImageUploadStatus.Uploading:
                return <Form.Text><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />  <span className="sr-only">Laster opp bilde...</span></Form.Text>;
            case ImageUploadStatus.SuccessIngredientsFound:
                return <Form.Text className="text-success">Fant ingredienser i bildet</Form.Text>;
            case ImageUploadStatus.NoResultsFound:
                return <Form.Text className="text-warning">Fant ingen ingredienser i bildet</Form.Text>;
            case ImageUploadStatus.Failed:
                return <Form.Text className="text-danger">Kunne ikke laste opp bilde</Form.Text>;
            default:
                return null;
        }
    };

    const formatAllergener = (allergenMapping: AllergenMap[]) => {
        // plukk ut allegener fra allergenMappingen 
        const allergener = allergenMapping.map(o => o.allergen);

        // hvis allegenet er overliggende skriver vi bare ut navnet, ellers så må vi formatter både overliggende og underliggende
        const allegenerAsString: string[] = allergener.map(allergen => typeof (allergen) === 'string' ? allergen : allergen.underliggende + ' (' + allergen.overliggende + ')');

        // fjern duplikater, Set removes duplikates
        const uniqueAllergener = Array.from(new Set(allegenerAsString));

        // til komma separert liste
        return uniqueAllergener.join(', ');
    }

    const formatAllergierklaring = (allergenMapping: AllergenMap[]) => {
        return Array.from(new Set(allergenMapping.map(o => o.ingrediens))).join(', ');
    }

    return (
        <>
            <h1 className="mt-3">Ingrediensliste-forbedrer (beta)</h1>
            <p className="alert alert-primary">
                Prøv vår nye AI som forbedrer ingredienslister og foreslår allergener basert på ingrediensene. Den oversetter, retter skrivefeil og fjerner unødvendig tekst. Det er ikke alltid at forslagene blir riktig, så husk å kvalitetssikre før bruk.
            </p>

            {isTradesolutionAnsatt && (
                //Button to change the visibility of the textfields
                <Button onClick={changePromptFields} className="mt-3" variant="outline-primary">
                    {showPromptFields ? 'Skjul felter' : 'Rediger KI-prompt'}
                </Button>
            )}

            {/* render the fields based on the state */}
            {showPromptFields && (
                <>
                    <Row className="mt-3">
                        <Col>
                            <Form.Label>Prompt for ingrediensvask</Form.Label>
                            <Form.Control value={ingrediensVaskPrompt} as="textarea" rows={7} onChange={e => setIngrediensVaskPrompt(e.target.value)} />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col>
                            <Form.Label>Prompt for allergenmapping</Form.Label>
                            <Form.Control value={allergenmappingPrompt} as="textarea" rows={7} onChange={e => setAllergenMappingPrompt(e.target.value)} />
                        </Col>
                    </Row>
                </>
            )}
            <Row className="mt-3">
                <Col>
                    <Row>
                        <Col>
                            <Form.Label>Ingrediensliste{ingrediensListe === exampleText &&
                                <>
                                    <span>. Vi har lagt inn en eksempel ingrediensliste som du kan teste med eller erstatte med din egen</span>&nbsp;

                                </>
                            }
                            </Form.Label>

                        </Col>
                        <Col className="text-end">
                            <Form.Label>
                                <a href="#imageUploadContainer" onClick={(e) => toggleImageUpload(e)} aria-expanded="false" aria-controls="collapseExample">
                                    Trykk her for å laste opp et bilde av en ingrediensliste
                                </a>
                            </Form.Label>
                        </Col>

                    </Row>

                    <Collapse in={showImageUpload}>
                        <div id="image-upload" className="mt-3 mb-3">
                            <Form.Group controlId="imageUpload">
                                <Form.Control type="file" onChange={handleImageUpload} />
                                <div className="mt-2">
                                    {displayImageUploadStatus()}
                                </div>
                            </Form.Group>
                        </div>
                    </Collapse>

                    <Form.Control value={ingrediensListe} as="textarea" rows={7} placeholder="Legg inn en liste med ingredienser" onChange={e => setIngrediensListe(e.target.value)} />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <IconButton icon="send" onClick={() => vaskIngrediensListe(ingrediensListe)}>
                        Foreslå forbedringer
                    </IconButton>
                </Col>
            </Row>
            {vaskerIngrediensListe && <Row className="mt-3">
                <Col>
                    <div className="loader-container">
                        <SpinnerWithText text="Formatterer og retter skrivefeil" />
                    </div>
                </Col>
            </Row>}
            {!!correctedIngrediensListe &&
                <Row className="mt-3">
                    <Col>
                        <strong>Forslag til ingrediensliste</strong><br />
                        {correctedIngrediensListEmphasized ? HTMLReactParser(correctedIngrediensListEmphasized) : correctedIngrediensListe}
                        <CopyToClipboardIcon val={correctedIngrediensListe} />
                    </Col>
                </Row>
            }
            {comment &&
                <Row className="mt-3">
                    <Col>
                        <strong>Kommentarer</strong><br />
                        {comment}
                    </Col>
                </Row>
            }
            {finnerAllergener && <Row className="mt-3">
                <Col>
                    <div className="loader-container">
                        <SpinnerWithText text="Finner allergener i ingrediensliste" />
                    </div>

                </Col>
            </Row>}
            {!vaskerIngrediensListe && !finnerAllergener && correctedIngrediensListe && (!allergenMapping || allergenMapping.length === 0) && <>
                <Row className="mt-3">
                    <Col>
                        <strong>Allergieklæring</strong><br />
                        Ingen allergener identifisert
                    </Col>

                </Row>
                <Row className="mt-3">
                    <Col>
                        <strong>Allergener</strong><br />
                        Ingen allergener identifisert
                    </Col>
                </Row>
            </>}
            {!!allergenMapping && allergenMapping.length > 0 && <>
                <Row className="mt-3">
                    <Col>
                        <strong>Allergierklæring (Alle ingredienser som inneholder allergener)</strong><br />
                        {formatAllergierklaring(allergenMapping)}
                        <CopyToClipboardIcon val={formatAllergierklaring(allergenMapping)} />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <strong>Allergener</strong><br />
                        {formatAllergener(allergenMapping)}
                        <CopyToClipboardIcon val={formatAllergener(allergenMapping)} />
                    </Col>
                </Row>
            </>}
            {errorText &&
                <Row className="mt-3">
                    <Col>
                        <Alert variant="danger">{errorText}</Alert>
                    </Col>
                </Row>}
            {!!correctedIngrediensListe &&
                <Row className="mt-3">
                    <Col>
                        <h4>Er du fornøyd med svaret? </h4>
                        <IconButton variant={thumbsUpValue && thumbsUpValue === "true" ? "success" : "outline-success"} icon="thumbs-up" onClick={() => sendThumbsUp(true)}></IconButton>
                        {' '}
                        <IconButton variant={thumbsUpValue && thumbsUpValue === "false" ? "danger" : "outline-danger"} icon="thumbs-down" onClick={() => sendThumbsUp(false)}></IconButton>
                        <br />
                        {thumbsUpSendt && (
                            <div className="mt-3">
                                <Form.Label>Hjelp oss å gjøre forslagene bedre ved å legge inn kommentarer på hva du er misfornøyd eller fornøyd med</Form.Label>
                                <Form.Control
                                    value={kommentar}
                                    as="textarea"
                                    rows={3}
                                    placeholder="Skriv din kommentar her"
                                    onChange={(e) => setKommentar(e.target.value)}
                                />

                                <Button className="mt-3 mb-3" onClick={sendKommentar} disabled={lagreData}>
                                    {lagreData ? 'Lagrer...' : 'Send kommentar'}
                                </Button>
                            </div>
                        )}
                    </Col>
                </Row>}
            {kommentarSendt && <Row className="mt-3">
                <Col>
                    <Alert variant="success" onClose={() => setShow(false)} dismissible>Takk for tilbakemelding</Alert>
                </Col>
            </Row>}
        </>
    )


}

export default IngredienserPage;


