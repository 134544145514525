import { Spinner } from "react-bootstrap";

interface Props {
    text?: string;
}

const SpinnerWithText = (props: Props) => {
    return (
        <>
            <Spinner />
            <span style={{ fontSize: '16px', paddingTop: '1em', color: 'grey' }}>{props.text ?? 'Loading'}</span>
        </>
    );
}

export default SpinnerWithText;