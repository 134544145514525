import { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import * as signalR from "@microsoft/signalr";
import { useMsal } from "@azure/msal-react";

const SignalRTest = () => {

    const getBaseUrl = () => {
        let baseUrl = 'https://einsteinapi.tradesolution.no';
        if (window.location.host.indexOf('dev') > -1)
            baseUrl = 'https://einsteinapi-dev.tradesolution.no';

        if (window.location.host.indexOf('localhost') > -1)
            //this.baseUrl = 'https://adminportalenapi-dev.tradesolution.no/api';
            baseUrl = 'https://localhost:7255';

        return baseUrl;
    }

    const { instance, accounts } = useMsal();
    const name = accounts[0] && accounts[0].name;

    const connection = new signalR.HubConnectionBuilder()
        .withUrl(`${getBaseUrl()}/chat`)
        .build();

    connection.start();



    const [messages, setMessages] = useState<string[]>([]);

    connection.on("ReceiveMessage", (user, message) => {
        // add messages
        setMessages([...messages, `${user}: ${message}`]);
    });

    const [message, setMessage] = useState<string>("");

    return (
        <div>
            <h1>SignalR Test</h1>
            <div className="alert alert-success">
                <InputGroup className="mb-3">
                    <Form.Control type="text" placeholder="Skriv melding" value={message} onChange={(e: any) => setMessage(e.target.value)} />
                    <Button onClick={() => connection.invoke("SendMessage", name, message)}>Send melding</Button>
                </InputGroup>
            </div>
            <div className="mt-3">
                {messages.map((message, index) => (
                    <div key={index}>{message}</div>
                ))}
            </div>
        </div>
    );
};

export default SignalRTest;