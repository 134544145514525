import React, { useEffect, useState } from 'react';
import Toaster from '.';
import { Toast } from './types';

export const ToastContext = React.createContext<any>(null);

interface Props {
  children: React.ReactNode;
}

const ToastProvider: React.FC<Props> = ({ children }) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const info = (title: string, msg: string) => {
    const infoToast: Toast = {
      type: 'primary',
      title: title,
      message: msg,
      dismissible: true,
      timed: true,
    };
    setToasts(toasts => [...toasts, infoToast]);
  };

  const success = (title: string, msg: string) => {
    const successToast: Toast = {
      type: 'success',
      title: title,
      message: msg,
      dismissible: true,
      timed: true,
    };
    setToasts(toasts => [...toasts, successToast]);
  };

  const processing = (title: string, msg: string, onCancelRequest: () => void) => {
    const warningToast: Toast = {
      type: 'warning',
      title: title,
      message: msg,
      dismissible: false,
      timed: false,
      onCancel: onCancelRequest,
    };
    setToasts(toasts => [...toasts, warningToast]);
  }

  const error = (title: string, msg: string, callback: void) => {
    const dangerToast: Toast = {
      type: 'danger',
      title: title,
      message: msg,
      dismissible: true,
      timed: false,     
    };
    setToasts(toasts => [...toasts, dangerToast]);
  };

  const handleCloseToast = (index: number) => {
    if (index > -1) {
      toasts.splice(index, 1);
    }
    setToasts(toasts => [...toasts]);
  };

  const findTimedToasts = () => toasts.filter(o => o.timed);
  const findOnlyDismissibleToasts = () => toasts.filter(o => o.dismissible && !o.timed);

  useEffect(() => {
    const timedToasts = findTimedToasts();
    const dismissibleToasts = findOnlyDismissibleToasts();
    const timer = setTimeout(() => {
      if (timedToasts.length) {
        timedToasts.shift();
        setToasts(timedToasts);
      }
      if (dismissibleToasts.length) {
        let merged = timedToasts.concat(dismissibleToasts);
        setToasts(merged);
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [toasts]);

  return (
    <ToastContext.Provider value={{ info, error, success, processing }}>
      <Toaster toasts={toasts} handleCloseToast={handleCloseToast} />
      {children}
    </ToastContext.Provider>
  );
};
export default ToastProvider;
