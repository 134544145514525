import { useEffect, useState } from "react";
import { Col, Form, Row, Table, InputGroup } from "react-bootstrap";
import EinsteinApi from "../../Services/EinsteinApi";
import { DataTypeEnum, IColumn, IcebergIcon, IcebergTable } from "@tradesolution/iceberg-ui-react";
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import useDebounce from "../../Shared/hooks/useDebouce";
import { OnClickWrapper } from "../../Shared/OnClickWrapper";
import { format } from "date-fns";
import * as _ from "lodash";
import { AISessionEntity, TilbakeMeldingerFilter } from "../../Services/EinsteinApi/model";

const TilbakemeldingerPage = () => {
    const [feedbackFilter, setFeedbackFilter] = useState<"all" | "good" | "bad" | "none">("all");
    const [commentFilter, setCommentFilter] = useState<"all" | "med" | "uten">("all");
    const [searchBox, setSearchBox] = useState<string>("");

    const [tilbakemeldinger, setTilbakemeldinger] = useState<AISessionEntity[]>([]);
    const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());


    const loadTilbakeMeldinger = async () => {
        const f: TilbakeMeldingerFilter = {
            feedbackfilter: feedbackFilter,
            kommentarFilter: commentFilter,
            searchText: searchBox
        };
        const data = await EinsteinApi.getTilbakemeldinger(f);

        data.forEach(i => {
            i.tidspunkt = i.timestamp ? formatTimestamp(i.timestamp) : '';
        })

        setTilbakemeldinger(data);
    }

    const [debouncedSearchBox, setDebouncedSearchBox] = useState<string>();

    useDebounce(() =>
        setDebouncedSearchBox(searchBox), 300,
        [searchBox]);

    useEffect(() => {
        loadTilbakeMeldinger();
        console.log("load")
    }, [feedbackFilter, commentFilter, debouncedSearchBox]);

    const getThumbsIcon = (thumbsUp: boolean | null) => {
        if (thumbsUp === null) {
            return null;
        }
        return thumbsUp ? <IcebergIcon icon="thumbs-up" className="text-success" /> : <IcebergIcon icon="thumbs-down" className="text-danger" />
    }

    const formatTimestamp = (timestamp: number) => {
        const date = new Date(timestamp * 1000);
        return format(date, "dd.MM.yyyy HH:mm");
    }

    // Clear search input
    const clearSearch = () => {
        setSearchBox("");
    }

    const formatOutput = (output: string) => {
        // deserialize output
        let obj = null;
        try {
            obj = JSON.parse(output);
        }
        catch (e) {
            console.error("Could not parse ai output as Json: " + output);
        }

        if (!obj)
            return null;

        // return formatted output
        return <div>
            <strong>Forslag til ingrediensliste</strong><br />
            {obj.ingredientSuggestion ?? ""}<br /><br />
            <strong>Kommentar</strong><br />
            {obj.comments}<br /><br />

        </div>;
    }

    const formatAllergenOutput = (output: string) => {
        // deserialize output
        let obj = null;
        try {
            obj = JSON.parse(output);
        }
        catch (e) {
            console.error("Could not parse ai output as Json: " + output);
        }

        if (!obj)
            return null;

        const allergenDeclaration = _.uniq(obj.map((item: any) => item.ingrediens)).join(', ');
        const allergens = _.uniq(obj.map((item: any) => {
            if (typeof item.allergen === 'string') {
                return item.allergen;
            } else {
                return `${item.allergen.overliggende} (${item.allergen.underliggende})`;
            }
        })).join(', ');

        // return formatted output
        return <div>
            <strong>Allergierklæring</strong><br />
            {allergenDeclaration}<br /><br />
            <strong>Allergener</strong><br />
            {allergens}
        </div>;
    }


    return (
        <div>
            <h1 className="mt-3">Tilbakemelding Oversikt</h1>
            <Row>
                <Col>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder="Søk etter brukere..."
                            value={searchBox}
                            onChange={(e) => setSearchBox(e.target.value)}
                            style={{ borderRight: 'hidden', borderRadius: '24px 0px 0px 24px' }}
                            autoFocus
                        />
                        <InputGroupText style={{ borderLeft: 'hidden', borderRadius: '0px 24px 24px 0px' }}>
                            {searchBox.length > 0 ? (
                                <OnClickWrapper onClick={clearSearch}>
                                    <IcebergIcon icon='close' cursor='pointer' />
                                </OnClickWrapper>
                            ) : (
                                <IcebergIcon icon='search' />
                            )}
                        </InputGroupText>
                    </InputGroup>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Form.Group>
                        <Form.Label>Filtrer tilbakemelding</Form.Label>
                        <Form.Control
                            as="select"
                            value={feedbackFilter}
                            onChange={(e) => setFeedbackFilter(e.target.value as "all" | "good" | "bad" | "none")}
                        >
                            <option value="all">Alle</option>
                            <option value="good">Bra</option>
                            <option value="bad">Dårlig</option>
                            <option value="error">Med systemfeil</option>
                            <option value="none">Ingen</option>
                        </Form.Control>

                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Filtrer kommentar</Form.Label>
                        <Form.Control
                            as="select"
                            value={commentFilter}
                            onChange={(e) => setCommentFilter(e.target.value as "all" | "med" | "uten")}
                        >
                            <option value="all">Alle</option>
                            <option value="med">Med kommentar</option>
                            <option value="uten">Ingen kommentar</option>
                        </Form.Control>

                    </Form.Group>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    {/* <div className="table-responsive"> */}
                    <IcebergTable
                        columns={[
                            {
                                dataField: 'tidspunkt',
                                title: 'Tidspunkt',
                                dateFormat: 'dd.MM.yyyy'
                            }, {
                                dataField: 'username',
                                title: 'Username',
                            }, {
                                dataField: 'thumbsUp',
                                title: 'Tilbakemelding',
                                dataType: DataTypeEnum.CHECKBOX,
                                formatter: (field, row, column, rowKey) => {
                                    return getThumbsIcon(row.thumbsUp);
                                }
                            },
                            {
                                dataField: 'kommentar',
                                title: 'Kommentar'
                            }
                        ]}
                        rows={tilbakemeldinger}
                        grid
                        neverHideColumns
                        rowExpansionFormatter={(row: AISessionEntity) => (
                            <Row>
                                {row.imageUrl && <Col>
                                    {row.imageUrl && (
                                        <div>
                                            <strong>Bilde</strong><br />
                                            <img src={row.imageUrl} alt="bilde" style={{ maxWidth: "100%", height: "450px" }} /><br /><br />
                                        </div>
                                    )}
                                    <strong>Bilde Opplasting Varighet:</strong> {(row.bildeOpplastingDurationInMilliSeconds / 1000).toFixed(2)} s
                                </Col>}
                                <Col>
                                    <strong>Input</strong><br />
                                    {row.input}<br /><br />
                                    {row.ingrediensVaskDeserializedFailed ? <><span className="text-danger">Deserialisering av ingrediensvask output feilet: </span><span>{row.output}</span></> : (formatOutput(row.output))}
                                    <strong>Ingrediens Vask Varighet:</strong> {(row.ingrediensVaskDurationInMilliSeconds / 1000).toFixed(2)} s<br />
                                    {row.ingrediensVaskDeserializedFailed && <span className="text-danger">Ingrediensvask feilet</span>}
                                </Col>
                                <Col>
                                    {formatAllergenOutput(row.allergenOutput)}<br />
                                    <strong>Raw output:</strong><br /> {row.allergenOutput}<br /><br />
                                    <strong>Allergen Mapping Varighet:</strong> {(row.allergenMappingDurationInMilliSeconds / 1000).toFixed(2)} s<br />
                                    {row.allergenDeserializedFailed && <span className="text-danger">Allergenmapping feilet</span>}
                                </Col>
                            </Row>
                        )}
                    />
                    {/*</div> */}
                </Col>
            </Row>
        </div>
    );
};

export default TilbakemeldingerPage;